import { getStorageData, saveStorageData } from 'app/localStorage/localStorage';
import { debounce } from 'app/utils/helpers';

const COOKIE_TITLE = 'CookiesAccepted';
const showCookieMessage = !getStorageData(COOKIE_TITLE) && document.querySelector('.cookie-banner') !== null;

const checkCookieOnLoad = (cookieName) => {
  const cookieBanner = document.querySelector('.cookie-banner');
  const cookieBannerCTA = document.querySelector('.cookie-banner__cta-link');
  const footer = document.querySelector('.footer');

  cookieBanner.classList.add('display-block');
  cookieBannerCTA.addEventListener('click', e => {
    e.preventDefault();
    cookieBanner.classList.remove('display-block');
    footer.style.marginBottom = 0;
  });

  footer.style.marginBottom = `${cookieBanner.offsetHeight}px`;
  const debounceTime = 10;
  window.addEventListener('resize', debounce(() => {
    footer.style.marginBottom = `${cookieBanner.offsetHeight}px`;
  }, debounceTime, true));

  saveStorageData(cookieName, 'true');
};

if (showCookieMessage) {
  checkCookieOnLoad(COOKIE_TITLE);
}
